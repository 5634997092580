.react-datepicker-wrapper input {
  height: 40px;
  border-radius: 0px;
  border: 1px solid #d8dae5;
  padding: 0 12px;
  width: 100%;
}

.react-datepicker-wrapper input:focus {
  border-color: #c9ccd6;
  outline: none;
}

.react-datepicker,
.react-datepicker-wrapper {
  position: relative;
}

.react-datepicker-popper {
  width: 244px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(216, 218, 229, 0.6);
  background-color: #ffffff;
  z-index: 1;
}

.react-datepicker-popper-ar {
  left: 0px !important;
}
.react-datepicker-popper-en {
  left: -28px !important;
}

.react-datepicker__week {
  display: flex;
  margin: 3px 0;
}

.react-datepicker__day-names {
  display: flex;
  padding: 0 5px;
  color: #90929c;
}

.react-datepicker__day--outside-month {
  color: #90929c;
}

.react-datepicker__day--selected {
  background-color: #3852ca;
  color: #fff;
}

.react-datepicker__month {
  padding: 0 5px 5px;
}

.react-datepicker__day,
.react-datepicker__day-name {
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  font-size: 12px;
  flex: 1;
  border-radius: 0.3rem;
}

.react-datepicker__day:hover:not(.react-datepicker__day--selected),
.react-datepicker__day-name:hover {
  background-color: #f0f0f0;
}

.react-datepicker__current-month {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0 8px;
}

.react-datepicker__navigation {
  position: absolute;
  width: 18px;
  height: 18px;
  color: transparent;
  font-size: 0;
  top: 2px;
}

.react-datepicker__navigation:before {
  font-family: 'Material Icons';
  font-size: 20px;
  color: #3852ca;
  line-height: 1;
  height: 100%;
}

.react-datepicker__navigation--previous {
  left: 12px;
}

.react-datepicker__navigation--previous:before {
  content: 'arrow_back';
}

.react-datepicker__navigation--next {
  right: 12px;
}

.react-datepicker__navigation--next:before {
  content: 'arrow_forward';
}

.react-datepicker__disabled-day {
  opacity: 0.4;
  color: #90929c;
  cursor: not-allowed;
  pointer-events: none;
}

/* disabled style */

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}
